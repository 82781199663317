<template>
  <div>
    <v-navigation-drawer
      app
      class="pt-4 container-sidebar-okan"
      color="#fff"
      :permanent="permanentE"
      mini-variant
      expand-on-hover
      style="height: 100%; z-index: 999999"
    >
      <v-avatar class="mb-2 ml-2" size="40">
        <img
          src="../assets/icon/logo_okan.png"
          alt="Logo-Okan"
          @click="goHome()"
        />
      </v-avatar>

      <v-list nav dense>
        <template v-for="(item, i) in items">
          <v-list-item
            :to="{ name: item.routeName }"
            @click="markClick(item)"
            link
            :class="item.class"
            :key="i"
            v-if="item.show"
            class="sidebar__items"
          >
            <v-list-item-icon>
              <img :class="item.classIcon" class="svg" :src="item.img" />
            </v-list-item-icon>
            <v-list-item-title
              class="txt-sidebar-d text-left"
              style="line-height: normal"
            >
              {{ $translate(`sidebar.${item.name}`) }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <v-list-item @click="goDevIA" link class="sidebar__items" v-if="showIA">
          <v-list-item-icon>
            <img :src="origin + '/icon/user-edit.svg'" />
          </v-list-item-icon>
          <v-list-item-title class="txt-sidebar-d text-left">
            {{ $translate("sidebar.AIDevelopmentRequests") }}
          </v-list-item-title>
        </v-list-item>

        <v-list-item
          @click="updateUser = true"
          link
          v-if="showUpdate"
          class="sidebar__items"
        >
          <v-list-item-icon>
            <img :src="origin + '/icon/user-edit.svg'" />
          </v-list-item-icon>
          <v-list-item-title class="txt-sidebar-d text-left">
            {{ $translate("sidebar.UserUpdate") }}
          </v-list-item-title>
        </v-list-item>

        <v-list-item @click="irLogin()" link class="sidebar__items">
          <v-list-item-icon>
            <img :src="origin + '/icon/logout.svg'" />
          </v-list-item-icon>
          <v-list-item-title class="txt-sidebar-d text-left">
            {{ $translate("sidebar.Logout") }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-footer app color="#466be3">
      <v-bottom-navigation background-color="#466be3" class="border-footer">
        <template v-for="(item, i) in itemsFooter">
          <v-btn
            large
            text
            class="item-navbar a-navbar btn-item mb-3 btn-n"
            :key="i"
            :to="{ name: item.routeName }"
          >
            <span class="icon-name"> {{ item.name }} </span>
            <img :class="item.class" :src="item.img" />
          </v-btn>
        </template>
      </v-bottom-navigation>
    </v-footer>

    <!-- MODALES -->
    <v-dialog max-width="400" v-model="modal.welcome">
      <v-card>
        <v-card-title>
          <v-layout class="" justify-center> ¡BIENVENIDO A OKAN! </v-layout>
        </v-card-title>
        <v-card-text>
          <img src="../assets/img/brm.png" />
          <p style="font-size: 18px">
            ¡Hola! Te presentamos {{ txtMobile }}, tu plataforma centralizadora
            de aplicativos y contenidos, acompáñame en un breve recorrido que le
            ayudará a conocer todas las facilidades que tenemos para usted.
          </p>
        </v-card-text>
        <v-card-actions class="pb-7">
          <v-btn @click="openStep()" block rounded class="btn-main" dark>
            continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="400" v-model="modal.close">
      <v-card>
        <v-card-text>
          <img src="../assets/img/brm.png" />
          <p style="font-size: 18px">
            Ahora ya está listo para empezar a usar OKAN
          </p>
          <p style="font-size: 18px"><strong> ¡Bienvenido! </strong></p>
        </v-card-text>
        <v-card-actions class="pb-7">
          <v-btn
            @click="modal.close = false"
            block
            rounded
            class="btn-main"
            dark
          >
            cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="updateUser" max-width="850">
      <v-card class="pa-3">
        <v-tabs v-model="tab" background-color="#466be3" centered dark dense>
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#tab-1">
            <strong> Actualización usuarios </strong>
          </v-tab>
          <v-tab href="#tab-2">
            <strong> Actualización Multiples usuarios </strong>
          </v-tab>
          <v-tab href="#tab-3">
            <strong> Verificación de usuarios </strong>
          </v-tab>
          <v-tab href="#tab-4">
            <strong> Aplicaciones por cédula </strong>
          </v-tab>
          <v-tab href="#tab-5">
            <strong> Aplicaciones por perfil </strong>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1">
            <v-form ref="form" lazy-validation>
              <v-row class="px-6">
                <v-col cols="12">
                  <p class="text-left mb-0 pb-0 mt-6" style="font-size: 19px">
                    Actualización de usuarios
                  </p>
                </v-col>
                <v-col cols="9" class="pt-0">
                  <v-text-field
                    v-model="documentNumber"
                    label="Cédula de ciudadanía"
                    required
                    @click:clear="reset()"
                    clear-icon="mdi-close-circle"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-btn
                    color="success"
                    class="mr-4"
                    @click="userSearch()"
                    :loading="loading4"
                    :disabled="loading4"
                    block
                  >
                    Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
            <v-form v-if="flagUserInfo">
              <v-row class="px-6">
                <v-col cols="12">
                  <v-text-field
                    label="Nombre"
                    type="string"
                    :value="userInfo.full_name"
                    required
                    disabled
                  ></v-text-field>
                  <v-text-field
                    label="Cédula de ciudadania"
                    type="string"
                    :value="userInfo.document_number"
                    required
                    disabled
                  ></v-text-field>
                  <v-text-field
                    label="País"
                    type="string"
                    :value="userInfo.country"
                    required
                    disabled
                  ></v-text-field>
                  <v-text-field
                    label="Nickname"
                    type="string"
                    v-model="userInfo.nickname"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Correo corporativo"
                    type="email"
                    v-model="userInfo.email"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-btn
                    color="success"
                    class="mr-4"
                    @click="updateUser = false"
                    :loading="loading5"
                    :disabled="loading5"
                    outlined
                    block
                  >
                    Cancelar
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn
                    color="success"
                    class="mr-4"
                    @click="updateInfoUser()"
                    :loading="loading5"
                    :disabled="loading5"
                    block
                  >
                    Actualizar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
          <v-tab-item value="tab-2">
            <v-form ref="form" lazy-validation>
              <v-row class="px-6">
                <v-col cols="12">
                  <p class="text-left mb-0 pb-0 mt-6" style="font-size: 19px">
                    Actualización de muliples usuarios
                  </p>
                </v-col>
                <v-col cols="9">
                  Por favor, descarga el formato para la actualización de
                  múltiples usuarios.
                </v-col>
                <v-col cols="3">
                  <v-btn
                    color="success"
                    class="mr-4"
                    @click="downloadFormat()"
                    :loading="loading4"
                    :disabled="loading4"
                    block
                  >
                    Descargar
                  </v-btn>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <v-file-input
                    v-model="file"
                    placeholder="Formato"
                    label="Adjuntar Formato"
                    accept=".xlsx"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip small label color="primary">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col cols="44" class="mt-4 mb-4">
                  <v-btn
                    outlined
                    block
                    color="success"
                    @click="updateUser = false"
                  >
                    Cancelar
                  </v-btn>
                </v-col>
                <v-col cols="6" class="mt-4 mb-4">
                  <v-btn
                    color="success"
                    class="mr-4"
                    @click="saveMultiUpdate()"
                    :loading="loading4"
                    :disabled="loading4"
                    block
                  >
                    Guardar Cambios
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>

          <v-tab-item value="tab-3">
            <v-row class="px-6">
              <v-col cols="12">
                <p class="text-left mb-0 pb-0 mt-6" style="font-size: 19px">
                  Verificación de usuarios
                </p>
              </v-col>
              <v-col cols="9" class="pt-0 pb-0">
                <v-text-field
                  v-model="documentNumber"
                  label="Cédula de ciudadanía"
                  class="mb-3"
                  required
                  @click:clear="reset()"
                  clear-icon="mdi-close-circle"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-btn
                  color="success"
                  class="mr-4"
                  @click="userSearchApps()"
                  :loading="loading4"
                  :disabled="loading4"
                  block
                >
                  Buscar
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                v-if="userInfo1.full_name != null"
                class="pt-0 pb-0"
              >
                <v-text-field
                  label="Nombre"
                  :value="userInfo1.full_name"
                  required
                  disabled
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                v-if="userInfo1.full_name != null"
                class="pt-0 pb-0"
              >
                <v-text-field
                  label="Cargo"
                  v-model="userInfo1.perfil"
                  required
                  disabled
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                v-if="userInfo1.full_name != null"
                class="pt-0 pb-0"
              >
                <v-text-field
                  label="País"
                  v-model="userInfo1.pais"
                  required
                  disabled
                ></v-text-field>
              </v-col>
              <v-divider></v-divider>
              <v-col
                cols="12"
                v-if="userInfo1.full_name != null"
                class="pt-0 pb-0"
              >
                <v-text-field
                  v-model="search"
                  class="mb-4"
                  append-icon="mdi-magnify"
                  label="Buscar aplicación"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                v-if="userInfo1.full_name != null"
                class="pt-0 pb-0"
              >
                <v-data-table
                  :headers="headers"
                  :items="listAppsUser"
                  :search="search"
                  :items-per-page="8"
                  :footer-props="{ itemsPerPageOptions: [8, 16] }"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item value="tab-4">
            <v-form ref="formApp">
              <v-row class="px-6">
                <v-col cols="12">
                  <p class="text-left mb-0 pb-0 mt-6" style="font-size: 19px">
                    Asignación de aplicaciones por cédula
                  </p>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <v-autocomplete
                    v-model="formApp._ID"
                    label="Seleccione aplicación"
                    item-text="nombre_app"
                    item-value="_ID"
                    :items="listAppsAll"
                    multiple
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <v-textarea
                    v-model="formApp.cedulas"
                    label="Ingrese listado de cédulas separado por enter"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formApp.fecha_expiracion"
                        label="Fecha de expiración"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="formApp.fecha_expiracion"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" class="mt-4 mb-4">
                  <v-btn
                    outlined
                    block
                    color="success"
                    @click="updateUser = false"
                  >
                    Cancelar
                  </v-btn>
                </v-col>
                <v-col cols="6" class="mt-4 mb-4">
                  <v-btn
                    color="success"
                    class="mr-4"
                    @click="appsSaveWithExpiration()"
                    :loading="loading4"
                    :disabled="loading4"
                    block
                  >
                    Guardar Cambios
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>

          <v-tab-item value="tab-5">
            <v-form ref="formProfile">
              <v-row class="px-6">
                <v-col cols="12">
                  <p class="text-left mb-0 pb-0 mt-6" style="font-size: 19px">
                    Asignación de aplicaciones por perfiles
                  </p>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <v-autocomplete
                    v-model="formProfile._ID"
                    label="Seleccione aplicación"
                    item-text="nombre_app"
                    item-value="_ID"
                    :items="listAppsAll"
                    multiple
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <v-autocomplete
                    v-model="formProfile.perfiles"
                    label="Seleccionar perfiles"
                    item-text="name"
                    item-value="post_id"
                    :items="listProfilesAll"
                    multiple
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="6" class="mt-4 mb-4">
                  <v-btn
                    outlined
                    block
                    color="success"
                    @click="updateUser = false"
                  >
                    Cancelar
                  </v-btn>
                </v-col>
                <v-col cols="6" class="mt-4 mb-4">
                  <v-btn
                    color="success"
                    class="mr-4"
                    @click="profileSaveWithApp()"
                    :loading="loading4"
                    :disabled="loading4"
                    block
                  >
                    Guardar Cambios
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
        </v-tabs-items>

        <v-card class="mt-4">
          <v-alert color="red" v-model="dialogAlert" prominent type="error">
            <p class="mb-0">{{ messageError }}</p>
          </v-alert>
        </v-card>
      </v-card>
    </v-dialog>

    <Alert
      :open="alert.open"
      :text="alert.txt"
      :title="alert.title"
      :type="alert.type"
      :redirect="alert.redirect"
      @close="alert.open = false"
    >
    </Alert>
  </div>
</template>
<script>
import { navigation } from "./navigation";
import Api from "@/utils/api";
import { saveLog } from "@/utils/auth";
import { stepJs } from "./step";
import moment from "moment";
import Alert from "./Alert.vue";
export default {
  components: {
    Alert,
  },
  data() {
    return {
      file: null,
      search: "",
      listAppsUser: [],
      listAppsAll: [],
      listProfilesAll: [],
      alert: {
        txt: "",
        title: "",
        type: "",
        open: false,
        redirect: "",
      },
      formApp: {
        _ID: null,
        cedulas: null,
        fecha_expiracion: null,
        min: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
      formProfile: {
        _ID: null,
        perfiles: [],
      },
      menu2: false,
      headers: [
        {
          text: "Aplicación",
          align: "start",
          value: "nombre",
        },
        { text: "Url", align: "start", value: "url" },
      ],
      tab: 1,
      origin: window.location.origin,
      permanentE: true,
      myOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: "SALTAR",
          buttonPrevious: "ANTERIOR",
          buttonNext: "SIGUIENTE",
          buttonStop: "FINALIZAR",
        },
      },
      myCallbacks: {
        onFinish: this.openCloseModal,
        onPreviousStep: this.myCustomPreviousStepCallback,
        onNextStep: this.myCustomNextStepCallback,
        onSkip: this.updateTour,
      },
      modal: {
        welcome: false,
        close: false,
        searchUser: false,
      },
      infoToken: {
        document: "",
      },
      txtMobile: "OKAN",
      steps: [
        // SECCIÓN
        {
          target: ".v-step-0",
          content:
            "El home cuenta con diferentes contenidos pensados para su mejor experiencia",
          before: (type) =>
            new Promise((resolve, reject) => {
              this.$router.push({ name: "home" });
              resolve("ok");
            }),
        },
        {
          target: ".v-step-1",
          content:
            "De este lado encontrará noticias de su interés de las diferentes áreas de BRM",
        },
        {
          target: ".v-step-2",
          content:
            "Flasy le mantendrá actualizado con videos informativos cortos ",
        },
        {
          target: ".v-step-3",
          content:
            "Y el reel muestra todas las noticias que tenemos para usted",
          params: {
            placement: "top",
          },
        },
        {
          target: ".v-step-4",
          content:
            "Aquí puede encontrar acciones sencillas que le llevarán directamente al aplicativo que está buscando con solo un click",
          params: {
            placement: "top",
          },
        },
        {
          target: ".v-step-5",
          content:
            "Asesórese con los mejores para conseguir todas sus metas seleccionando el proyecto que más le llama la atención",
          params: {
            placement: "top",
          },
          before: (type) =>
            new Promise((resolve, reject) => {
              if (type == "previous") {
                this.$router.push({ name: "home" });
              }
              resolve("ok");
            }),
        },
        {
          target: ".v-step-6",
          content:
            "La sección de noticias le mantiene informado de actualidad y coyunturas de la compañía ",
          params: {
            placement: "top",
          },
          before: (type) =>
            new Promise((resolve, reject) => {
              if (type == "next") {
                this.$router.push({ name: "listNews" });
              }
              resolve("ok");
            }),
        },
        {
          target: ".v-step-7",
          content: "Últimas noticias publicadas",
          params: {
            placement: "top",
          },
        },
        {
          target: ".v-step-8",
          content: "Todas las noticias de interés",
          params: {
            placement: "top",
          },
        },
        {
          target: ".v-step-9",
          content: "Videos informativos",
        },

        // SECCIÓN APLICATIVOS

        {
          target: ".v-step-10",
          content:
            "En la sección de aplicativos encuentra todo lo que necesita para su día a día",
          before: (type) =>
            new Promise((resolve, reject) => {
              this.$router.push({ name: "apps" });
              resolve("ok");
            }),
        },
        {
          target: ".v-step-11",
          content:
            "Todos sus aplicativos diferenciados según sus características",
          params: {
            placement: "top",
          },
        },
        {
          target: ".v-step-12",
          content:
            "Puede hacer uso de todos los aplicativos que tengas en su visual ¡Haz click en el siguiente icono!",
          params: {
            placement: "left",
          },
        },
        {
          target: ".v-step-13",
          content:
            "Encontrará una breve descripción del aplicativo al que está ingresando",
          params: {
            placement: "top",
          },
        },
        {
          target: ".v-step-14",
          content: "Puede hacer click para tener una redirección automática",
          params: {
            placement: "top",
          },
        },

        // SECCION DE FORMULARIOS

        {
          target: ".v-step-25",
          content:
            "Conoce las encuestas formularios y respuestas disponibles para usted ",
          params: {
            placement: "top",
          },
          before: (type) =>
            new Promise((resolve, reject) => {
              this.$router.push({ name: "forms" });
              resolve("ok");
            }),
        },

        {
          target: ".v-step-26",
          content: "Aquí sus formularios pendientes por diligenciar ",
          params: {
            placement: "top",
          },
        },

        {
          target: ".v-step-27",
          content: "Acá las respuestas de los formularios anteriores ",
          params: {
            placement: "top",
          },
        },

        // SECCION DE DOCUMENTOS

        {
          target: ".v-step-28",
          content: "Conoce los documentos que la compañía dispone para usted ",
          params: {
            placement: "top",
          },
          before: (type) =>
            new Promise((resolve, reject) => {
              this.$router.push({ name: "documents" });
              resolve("ok");
            }),
        },

        {
          target: ".v-step-29",
          content: "Conoce los documentos que la compañía dispone para usted ",
          params: {
            placement: "top",
          },
        },

        {
          target: ".v-step-30",
          content: "Aquí sus documentos pendientes por revisar ",
          params: {
            placement: "top",
          },
        },

        // SECCION DE SOLICITUDES

        {
          target: ".v-step-31",
          content:
            "Encuentra los aplicativos necesarios para completar sus solicitudes",
          params: {
            placement: "top",
          },
          before: (type) =>
            new Promise((resolve, reject) => {
              this.$router.push({ name: "actionToday" });
              resolve("ok");
            }),
        },
        {
          target: ".v-step-32",
          content:
            "Enlaces a la mano, que le llevan a gestionar sus solicitudes",
          params: {
            placement: "top",
          },
        },

        // SECCIÓN CONTROL DE VIDA

        {
          target: ".v-step-15",
          content:
            "La sección de control de vida le facilita encontrar asesoría para sus planes futuros",
          params: {
            placement: "top",
          },
          before: (type) =>
            new Promise((resolve, reject) => {
              this.$router.push({ name: "lifeControl" });
              resolve("ok");
            }),
        },
        {
          target: ".v-step-16",
          content:
            "De acuerdo al tipo de proyecto puede escoger el ítem que prefiera",
          params: {
            placement: "top",
          },
        },

        // SECCIÓN DE PERFIL

        {
          target: ".v-step-17",
          content:
            "Acá podrá encontrar toda la información relacionada con su perfil empresarial ",
          params: {
            placement: "top",
          },
          before: (type) =>
            new Promise((resolve, reject) => {
              this.$router.push({ name: "profile" });
              resolve("ok");
            }),
        },
        {
          target: ".v-step-18", // Fotoooo
          content: "Escoja su mejor foto de perfil",
          params: {
            placement: "top",
          },
        },
        {
          target: ".v-step-19",
          content: "Agrupe su información personal",
          params: {
            placement: "top",
          },
        },
        {
          target: ".v-step-20",
          content:
            "Muestra la información relacionada a sus procesos contractuales ",
          params: {
            placement: "top",
          },
        },
        // {
        //   target: ".v-step-21",
        //   content:
        //     "Aquí encontrarás la información relacionada con los equipos de tecnología que trabajas",
        //   params: {
        //     placement: "top",
        //   },
        // },
        // {
        //   target: ".v-step-22",
        //   content:
        //     "Por último vas a encontrar información de seguimiento de todos tus procesos internos dentro de la compañía",
        //   params: {
        //     placement: "top",
        //   },
        // },

        // SECCIÓN

        // {
        //   target: ".v-step-23",
        //   content:
        //     "Las notificaciones te mantienen al día de tus últimas novedades",
        //   params: {
        //     placement: "top",
        //   },
        // },
        {
          target: ".v-step-24",
          content: "Encuentre fácilmente sus aplicativos más usados",
          params: {
            placement: "top",
          },
        },
      ],
      step: null,
      items1: [],
      items: navigation.pathUser,
      info: {},
      itemsFooter: [
        {
          name: "Explorar",
          // icon: "mdi-view-day-outline",
          img: origin + "/icon/more.svg",
          routeName: "service",
          class: "more-blue v-step-mobile-12",
        },
        {
          name: "Inicio",
          // icon: "mdi-home-outline",
          img: origin + "/icon/home-2.svg",
          routeName: "home",
          class: "home-blue v-step-mobile-0",
        },
        {
          name: "Apps",
          // icon: "mdi-apps",
          img: origin + "/icon/more-apps.svg",
          routeName: "apps",
          class: "apps-blue v-step-mobile-6",
        },
      ],
      listApplications: [],
      updateUser: false,
      documentNumber: null,
      tokenB64: null,
      userInfo: {},
      userInfo1: {
        full_name: null,
        perfil: null,
      },
      flagUserInfo: false,
      loading4: false,
      loading5: false,
      dialogAlert: false,
      messageError: "",
      showUpdate: false,
      showUserSeacrh: false,
      isApps: true,
      references: true,
      listUserAll: [],
      selectUser: {
        full_name: "",
        img: "",
        document_number: "",
        cc: "",
        cod_scc: "",
        campaign: "",
        profile: {
          name: "",
        },
      },
      callAllUsers: false,
      showIA: false,
      dataIaDev: [],
      currentCountry:''
    };
  },
  computed: {
    count() {
      let user = this.$store.getters.getUser;
      if (user && this.isApps) {
        this.getApps(user.profile.id);
        this.currentCountry = user.country;
        console.log(user)
        // this.getFormsAnswer(user.document_number);
      }
    },
    user() {
      let userFlag = this.$store.getters.getUserProfileImg;
      if (userFlag) {
        this.getProfile();
        this.$store.dispatch("updateUserProfileImg");
      }
    },
  },
  methods: {
    markClick(item) {
      saveLog(this.infoToken.document, item.admId, item.admName);
    },
    userSearch() {
      if (this.documentNumber) {
        this.loading4 = true;
        let config = {
          headers: {
            Authorization: "Bearer " + this.tokenB64,
          },
        };
        let data = {
          document_number: this.documentNumber,
        };
        Api.Auth()
          .getByDocument(data, config)
          .then((res) => {
            this.userInfo = res.data.data;
            this.flagUserInfo = true;
          })
          .catch((error) => {
            this.dialogAlert = true;
            setTimeout(() => {
              this.dialogAlert = false;
            }, 3000);
            this.messageError = error.response.data.message;
            this.flagUserInfo = false;
          })
          .finally(() => {
            this.$refs.form.reset();
            this.loading4 = false;
          });
      }
    },
    updateInfoUser() {
      if (this.userInfo.nickname || this.userInfo.email) {
        this.loading5 = true;
        let config = {
          headers: {
            Authorization: "Bearer " + this.tokenB64,
          },
        };
        Api.Auth()
          .updateOkanAccess(this.userInfo, config)
          .then(() => {
            this.$refs.form.reset();
            this.flagUserInfo = false;
          })
          .catch((error) => {
            this.dialogAlert = true;
            setTimeout(() => {
              this.dialogAlert = false;
            }, 3000);
            this.messageError = error.response.data.message;
          })
          .finally(() => {
            this.loading5 = false;
          });
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    openStep() {
      this.modal.welcome = false;
      this.$tours["myTour"].start();
    },
    irLogin() {
      let dispositivo;
      if (screen.width < 1024) {
        dispositivo = "Mobile";
      } else {
        dispositivo = "Desktop";
      }
      const record = {
        userid: this.infoToken.document,
        appid: "80",
        appname: "Logout Okan",
        date: moment().format("YYYY-MM-DD"),
        dispositivo,
      };
      const recordLogout = {
        colaborador: this.infoToken.document,
        evento: "logout",
        aplicativo: "okan",
        fecha: moment().format("YYYY-MM-DD"),
        hora: moment().format("HH:mm:ss"),
      };

      Api.noAuth()
        .logoutUser(recordLogout)
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
        });

      Api.noAuth()
        .saveAppActivity(record)
        .then((resp) => resp.json())
        .then((data) => {
          localStorage.clear();
          sessionStorage.clear();
          var min = 12300;
          var max = 34290;
          var noCache = Math.floor(Math.random() * (max - min + 1) + min);
          window.location.href =
            process.env.VUE_APP_FT_LOGOUT + '/?noCache="' + noCache + '"';
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showApps() {},
    openCloseModal() {
      this.modal.close = true;
    },
    myCustomPreviousStepCallback(currentStep) {
      currentStep--;
      this.step = currentStep - 1;
      this.$root.$emit("stepV" + this.step);
    },
    myCustomNextStepCallback(currentStep) {
      this.step = currentStep + 1;
      this.$root.$emit("stepV" + this.step);
      if (currentStep == 3) {
        this.updateTour();
      }
    },
    async getApps(profileId) {
      this.isApps = false;
      this.loadingAll = true;
      this.id_profile = await sessionStorage.getItem("id_profile");
      var cc = this.infoToken.document;
      Api.noAuth()
        .getApps(cc, profileId)
        .then((resp) => resp.json())
        .then((data) => {
          this.$store.dispatch("updateApps", data);
          this.loadingAll = false;
          this.listApplications = [];
          const apps = data.data;
          const localStorageApps = JSON.stringify(apps);
          localStorage.setItem("apps", localStorageApps);

          const visibilityState = {};

          apps.forEach((app) => {
            if (app.es_un_modulo && app._ID == 2)
              visibilityState["ocupation"] = true;
            if (app._ID == 358) {
              visibilityState["selection"] = true;
            }
            if (
              app._ID == 652 ||
              app._ID == 653 ||
              app._ID == "651" ||
              app._ID == "470"
            ) {
              visibilityState["security"] = true;
              this.callAllUsers = true;
            }
            if (app._ID == 324) {
              this.references = false;
              this.$store.dispatch("updatePersonalReference");
            }
            if (
              app._ID == 439 ||
              app._ID == 484 ||
              app._ID == 440 ||
              app._ID == 485
            ) {
              visibilityState["newDevelopment"] = true;
              this.callAllUsers = true;
            }
            if(app._ID == "782"){
              visibilityState["workforceRequests"] = true;
              this.callAllUsers = true;
            }
            if (app._ID == 457 || app._ID == 553)
              visibilityState["carnet"] = true;
            if (app._ID == 459 || app._ID == 558)
              visibilityState["referenceReport"] = true;
            if (app._ID == 464 || app._ID == 621)
              visibilityState["laborRelations"] = true;
            if (app._ID == "319") this.showUpdate = true;
            if (app._ID == "335") this.showUserSeacrh = true;
            if (app._ID == "467" || app._ID == "619") {
              this.callAllUsers = true;
            }
            if (
              app._ID == "629" ||
              app._ID == "630" ||
              app._ID == "466" ||
              app._ID == "467"
            ) {
              this.showIA = true;
              if (app.url != "") {
                this.dataIaDev = app;
              }
            }
          });

          // Lista de rutas que siempre deben estar visibles por defecto
          const defaultVisibleRoutes = ['home', 'listNews', 'podcast', 'apps', 'documents', 'forms', 'actionToday', 'programsBrm', 'profile', 'tyc'];
          
          // Aplicar visibilidad a los items
          this.items = this.items.map(item => {
            // Copiar el item para no mutar el original
            const newItem = { ...item };
            
            // Si está en las rutas por defecto, mostrar
            if (defaultVisibleRoutes.includes(item.routeName)) {
              newItem.show = true;
            } else {
              // Para las demás rutas, verificar visibilityState
              newItem.show = visibilityState[item.routeName] || false;
            }
            
            // Si tiene restricción de país, verificar eso independientemente
            if (item.pais && !item.pais.includes(this.currentCountry)) {
              newItem.show = false;
            }
            
            return newItem;
          });

          if (this.callAllUsers) {
            this.getUsers();
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.getReference(this.references);
        });
    },
    userSearchApps() {
      this.loading4 = true;
      Api.noAuth()
        .userSearchApps(this.documentNumber)
        .then((resp) => resp.json())
        .then((data) => {
          this.loading4 = false;
          this.userInfo1 = data.data;
          // console.log(this.userInfo1)
          this.listAppsUser = data.data.apps;
        })
        .catch((error) => {
          this.loading4 = true;
          console.log(error);
        });
    },
    appsAll() {
      this.loading4 = true;
      Api.noAuth()
        .appsAll()
        .then((resp) => resp.json())
        .then((data) => {
          this.loading4 = false;
          this.listAppsAll = data.data;
        })
        .catch((error) => {
          this.loading4 = true;
          console.log(error);
        });
    },
    appsProfiles() {
      this.loading4 = true;
      Api.noAuth()
        .profileAll()
        .then((resp) => resp.json())
        .then((data) => {
          this.loading4 = false;
          this.listProfilesAll = data.data;
        })
        .catch((error) => {
          this.loading4 = false;
          console.log(error);
        });
    },
    separarString(value) {
      return value.split(/\r\n|\r|\n/, -1);
    },
    appsSaveWithExpiration() {
      this.loading4 = true;
      var data = {
        _ID: this.formApp._ID,
        nuevas_cedulas: this.separarString(this.formApp.cedulas),
        fecha_expiracion: this.formApp.fecha_expiracion,
      };
      Api.noAuth()
        .appsSaveWithExpiration(data)
        .then((resp) => resp.json())
        .then((data) => {
          this.loading4 = false;
          // mensaje
          this.updateUser = true;
          this.alert.open = true;
          this.alert.type = "success";
          this.alert.title = "¡Excelente!";
          this.alert.txt = "Se actualizo correctamente";
          this.alert.redirect = "";
          this.$refs.formApp.reset();
        })
        .catch((error) => {
          this.loading4 = true;
          console.log(error);
        });
    },
    profileSaveWithApp() {
      this.loading4 = true;
      Api.noAuth()
        .profileSaveWithApp(this.formProfile)
        .then((resp) => resp.json())
        .then((data) => {
          this.updateUser = true;
          this.loading4 = false;
          this.alert.open = true;
          this.alert.type = "success";
          this.alert.title = "¡Excelente!";
          this.alert.txt = "Se actualizo correctamente";
          this.alert.redirect = "";
          this.$refs.formProfile.reset();
        })
        .catch((error) => {
          this.loading4 = true;
          console.log(error);
        });
    },
    getToken() {
      var token = localStorage.getItem("token");
      var div = token.split(".");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    getProfile() {
      Api.Auth()
        .currentUser(this.token)
        .then((res) => {
          if (res.data.cod == 0) {
            this.info = res.data.data;
            this.tour = res.data.data.tour;
            var tourMobile = res.data.data.tour_mobile;
            if (screen.width < 1024) {
              if (!tourMobile) {
                this.modal.welcome = false;
              }
            } else {
              if (!this.tour) {
                this.modal.welcome = false;
              }
            }
            sessionStorage.setItem("user_id", res.data.data.id);
            sessionStorage.setItem("contractor", res.data.data.contractor);
            sessionStorage.setItem("fullName", this.info.full_name);
            sessionStorage.setItem(
              "document_number",
              res.data.data.document_number
            );
            sessionStorage.setItem("id_profile", this.info.profile.id);
            sessionStorage.setItem("dateAdmision", this.info.date_admission);
            sessionStorage.setItem("img", this.info.img);
            sessionStorage.setItem("city", this.info.residence_city);
            sessionStorage.setItem("phone", this.info.phone);
            this.$store.dispatch("updateUserInfo", res.data.data);
            if (
              localStorage.getItem("ocupationPermissions") == "true" &&
              this.isApps == true
            ) {
              this.getApps(res.data.data.profile.id);
            }
            this.getFormsAnswer(res.data.data.document_number);
            this.getFormsNoResponse(
              res.data.data.document_number,
              res.data.data.profile.id
            );
            this.getPerforming();
            this.getStatusControl(res.data.data.document_number);
            this.getDocumentSign();
            // this.getUsers();
          } else {
            this.alert.open = true;
            this.alert.type = "info";
            this.alert.title = "Hemos tenido algunos inconvenientes";
            this.alert.txt = "Estamos presentando algunos inconvenientes";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateTour() {
      var type = "desktop";
      if (screen.width < 1024) {
        type = "mobile";
      }
      Api.Auth()
        .hideTour(this.infoToken.document, type)
        .then((res) => {
          if (res.data.cod == 0) {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goHome() {
      this.$router.push({ name: "home" });
    },
    getFormsAnswer(document) {
      Api.noAuth()
        .getFormsAnswer(document)
        .then((resp) => resp.json())
        .then((data) => {
          // let array = data.data;
          // let removeDuplicate = (arr) => {
          //   let appeared = {};
          //   for (let i = 0; i < arr.length; ) {
          //     if (!appeared.hasOwnProperty(arr[i].title)) {
          //       appeared[arr[i].title] = 1;
          //       i++;
          //       continue;
          //     }
          //     arr.splice(i, 1);
          //   }
          //   return arr;
          // };
          // let formFilter = removeDuplicate(array);
          this.$store.dispatch("updateFormsAnswer", data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFormsNoResponse(document, profile) {
      Api.noAuth()
        .getFormsNoResponse(document, profile)
        .then((resp) => resp.json())
        .then((data) => {
          this.$store.dispatch("updateFormsNoResponse", data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPerforming() {
      Api.noAuth()
        .getPerforming(this.tokenB64)
        .then((res) => {
          if (res.data.cod == 0) {
            this.$store.dispatch("updatePerformingData", res);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getStatusControl(document) {
      let controlLife = {
        flag: false,
        projects: [],
        activeRequest: null,
      };
      Api.noAuth()
        .getStatusControl(document)
        .then((resp) => resp.json())
        .then((data) => {
          // if (!data.data) {
          if (data.data) {
            controlLife.activeRequest = data.data[0];
          }
          Api.noAuth()
            .getControl()
            .then((resp) => resp.json())
            .then((car) => {
              if (!data.data) {
                controlLife.flag = true;
              } else {
                controlLife.flag = false;
              }

              // controlLife.flag = true;
              car.forEach((app) => {
                controlLife.projects.push(app);
              });
            })
            .catch((error) => {
              console.log(error);
            });
          // }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.dispatch("updateStatusControl", controlLife);
        });
    },
    getDocumentSign() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.tokenB64,
        },
      };
      let documents = {
        completed: null,
        pending: null,
      };
      Api.Auth()
        .signCompleted(config)
        .then((res) => {
          if (res.data.cod == 0) {
            documents.completed = res.data.data;
            Api.Auth()
              .signPending(config)
              .then((res) => {
                if (res.data.cod == 0) {
                  documents.pending = res.data.data;
                }
              })
              .catch((err) => console.log(err));
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.$store.dispatch("updateDocuments", documents);
        });
    },
    getReference(personalReference) {
      var data = {
        statusId: "",
        personalReference,
      };
      Api.LaborRelations()
        .getReference(this.tokenB64, data)
        .then((res) => {
          this.$store.dispatch("updateReferences", res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUsers() {
      Api.Auth()
        .getUsers(this.tokenB64)
        .then((res) => {
          if (res.data.cod == 0) {
            var listUsers = res.data.data;
            this.listUserAll = res.data.data;
            for (let i = 0; i < listUsers.length; i++) {
              listUsers[i].user =
                listUsers[i].full_name + " - " + listUsers[i].document_number;
              this.listUserAll[i].user =
                listUsers[i].full_name +
                " - " +
                listUsers[i].document_number +
                " - " +
                listUsers[i].nickname;
            }
            this.$store.dispatch("updateAllUsers", listUsers);
            this.$store.dispatch("updateAllUsersNickName", this.listUserAll);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    downloadFormat() {
      Api.noAuth()
        .managerDocumentsDownload("", this.tokenB64)
        .then((data) => {
          const url = data.data.data; // Reemplaza con la URL del archivo Excel
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.style.display = "none";
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveMultiUpdate() {
      var data = new FormData();
      let currentUser = this.$store.getters.getUser;
      data.append("file", this.file);
      data.append("currentUser", currentUser);
      Api.noAuth()
        .updateMultipleUsers(this.tokenB64, data)
        .then((data) => {
          if (data.data.data.length == 0) {
            this.file = null;
            this.updateUser = true;
            this.loading4 = false;
            this.alert.open = true;
            this.alert.type = "success";
            this.alert.title = "¡Excelente!";
            this.alert.txt = "Se actualizo correctamente";
            this.$refs.formApp.reset();
          } else {
            this.loading4 = false;
            this.updateUser = true;
            this.alert.open = true;
            this.alert.type = "info";
            this.alert.title = "Hemos tenido algunos inconvenientes";
            this.alert.txt =
              "Se te ha descargado un archivo Excel con los inconvenientes presentados";

            const csvContent = "data:text/csv;charset=utf-8,Cedula,Mensaje\n";
            // Formato de datos
            const csvData = data.data.data
              .map((item) => `${item.cedula},${item.mensaje}`)
              .join("\n");

            // Unir los encabezados y los datos
            const csv = csvContent + csvData;

            const encodedUri = encodeURI(csv);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "datos.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.$refs.formApp.reset();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goDevIA() {
      window.open(
        "https://aidev.okan.tools/login?token=" + this.token,
        "_blank"
      );
    },
  },
  watch: {
    selectUser: function (n) {
      if (!n) {
        this.selectUser = {
          full_name: "",
          img: "",
          document_number: "",
          cc: "",
          cod_scc: "",
          campaign: "",
          profile: {
            name: "",
          },
        };
      }
    },
    count: function (newCount, oldCount) {
      console.log("");
    },
    user: function (newCount, oldCount) {
      console.log("");
    },
  },
  created() {
    this.getToken();
    this.tokenB64 = localStorage.getItem("tokenB64");
  },
  mounted() {
    if (screen.width < 1024) {
      this.permanentE = false;
      this.txtMobile = "OKAN MOBILE";
      var stepMobile = [
        {
          target: ".v-step-mobile-0",
          content:
            "El home cuenta con diferentes contenidos pensados para tu mejor experiencia",
          before: (type) =>
            new Promise((resolve, reject) => {
              this.$router.push({ name: "home" });
              resolve("ok");
            }),
        },
        {
          target: ".v-step-mobile-1",
          content:
            "Flashy le mantendrá actualizado con videos informativos cortos ",
          params: {
            placement: "top",
          },
        },
        {
          target: ".v-step-mobile-2",
          content:
            "De este lado encontrará noticias de su interés de las diferentes áreas de BRM",
          params: {
            placement: "top",
          },
        },
        {
          target: ".v-step-mobile-3",
          content:
            "Aquí puede encontrar acciones sencillas que le llevarán directamente al aplicativo que está buscando con solo un click",
          params: {
            placement: "top",
          },
        },
        {
          target: ".v-step-mobile-4",
          content:
            "Asesórese con los mejores para conseguir todas sus metas seleccionando el proyecto que más le llama la atención",
          params: {
            placement: "top",
          },
        },
        {
          target: ".v-step-mobile-5",
          content: "Encuentre fácilmente sus aplicativos más usados",
          params: {
            placement: "top",
          },
        },
        {
          target: ".v-step-mobile-6",
          content:
            "En la sección de aplicativos en esta versión mobile encuentre aplicativos disponibles fuera de los espacios laborales ",
          before: (type) =>
            new Promise((resolve, reject) => {
              this.$router.push({ name: "apps" });
              resolve("ok");
            }),
        },
        {
          target: ".v-step-mobile-7",
          content:
            "Todos sus aplicativos diferenciados según sus características",
          params: {
            placement: "top",
          },
        },
        {
          target: ".v-step-mobile-8",
          content:
            "Puede hacer uso de todos los aplicativos que tengas en su visual ¡Haz click en el siguiente icono!",
          params: {
            placement: "top",
          },
        },
        {
          target: ".v-step-mobile-9",
          content:
            "Encontrará una breve descripción del aplicativo al que está ingresando",
          params: {
            placement: "top",
          },
        },
        {
          target: ".v-step-mobile-10",
          content:
            "Aquí encontrará breves tutoriales del funcionamiento de las herramientas",
          params: {
            placement: "top",
          },
        },
        {
          target: ".v-step-mobile-11",
          content: "Puede hacer click para tener una redirección automática",
          params: {
            placement: "top",
          },
        },
        {
          target: ".v-step-mobile-12",
          content:
            "La sección de explorar le muestra los módulos funcionales con los que cuenta su perfil en esta versión mobile, ¡Haz clic en el icono!",
          params: {
            placement: "top",
          },
          before: (type) =>
            new Promise((resolve, reject) => {
              this.$router.push({ name: "service" });
              resolve("ok");
            }),
        },
        {
          target: ".v-step-mobile-13",
          content:
            "La sección de noticias le mantiene informado de actualidad y coyunturas de la compañía, ¡Haz clic en el icono!",
          params: {
            placement: "top",
          },
        },
        // NOVEDADES
        {
          target: ".v-step-mobile-19",
          content: "Videos informativos",
          params: {
            placement: "top",
          },
          before: (type) =>
            new Promise((resolve, reject) => {
              this.$router.push({ name: "listNews" });
              resolve("ok");
            }),
        },
        {
          target: ".v-step-mobile-20",
          content: "Últimas noticias publicadas",
          params: {
            placement: "top",
          },
        },
        {
          target: ".v-step-mobile-21",
          content:
            "También encontrará fechas especiales de nuestros colaboradores",
          params: {
            placement: "top",
          },
        },
        // SERVICE
        {
          target: ".v-step-mobile-14",
          content:
            "Conoce las encuestas, formularios y respuestas disponibles para usted, ¡Haz clic en el icono! ",
          params: {
            placement: "top",
          },
          before: (type) =>
            new Promise((resolve, reject) => {
              this.$router.push({ name: "service" });
              resolve("ok");
            }),
        },
        // ENCUESTAS
        {
          target: ".v-step-mobile-22",
          content: "Aquí sus formularios pendientes por diligenciar  ",
          params: {
            placement: "top",
          },
        },
        {
          target: ".v-step-mobile-23",
          content: "Acá las respuestas de los formularios anteriores",
          params: {
            placement: "top",
          },
        },
        {
          target: ".v-step-mobile-15",
          content:
            "La sección de control de vida le facilita encontrar asesoría para sus planes futuros, ¡Haz clic en el icono!",
          params: {
            placement: "top",
          },
          before: (type) =>
            new Promise((resolve, reject) => {
              this.$router.push({ name: "service" });
              resolve("ok");
            }),
        },
        // PROYECTO
        {
          target: ".v-step-mobile-24",
          content:
            "De acuerdo al tipo de proyecto puede escoger el ítem que prefiera",
          params: {
            placement: "top",
          },
        },
        {
          target: ".v-step-mobile-16",
          content:
            "Acá podrá encontrar toda la información relacionada con su perfil empresarial, ¡Haz clic en el icono!",
          params: {
            placement: "top",
          },
          before: (type) =>
            new Promise((resolve, reject) => {
              this.$router.push({ name: "service" });
              resolve("ok");
            }),
        },
        // PERFIL
        {
          target: ".v-step-mobile-25",
          content: "Escoge su mejor foto de perfil  ",
          params: {
            placement: "top",
          },
        },
        {
          target: ".v-step-mobile-26",
          content: "Agrupe su información personal ",
          params: {
            placement: "top",
          },
        },
        {
          target: ".v-step-mobile-27",
          content:
            "Muestra su información relacionada a tus procesos contractuales ",
          params: {
            placement: "top",
          },
        },
        {
          target: ".v-step-mobile-17",
          content:
            "Encuentra los aplicativos necesarios para completar su solicitudes ",
          params: {
            placement: "top",
          },
          before: (type) =>
            new Promise((resolve, reject) => {
              this.$router.push({ name: "service" });
              resolve("ok");
            }),
        },
        // {
        //   target: ".v-step-mobile-28",
        //   content: "solicitudes sección ",
        //   params: {
        //     placement: "top",
        //   },
        // },
        // SERVICIOS
        {
          target: ".v-step-mobile-18",
          content:
            "Conoce los documentos que la compañía dispone para usted, ¡Haz clic en el icono! ",
          params: {
            placement: "top",
          },
          before: (type) =>
            new Promise((resolve, reject) => {
              this.$router.push({ name: "service" });
              resolve("ok");
            }),
        },
        {
          target: ".v-step-mobile-29",
          content: "Aquí sus documentos pendientes por revisar ",
          params: {
            placement: "top",
          },
        },
        {
          target: ".v-step-mobile-30",
          content: "Acá los documentos revisados y firmados ",
          params: {
            placement: "top",
          },
        },
      ];
      this.steps = stepMobile;
    }
    this.id_profile = sessionStorage.getItem("id_profile");
    this.getProfile();
    this.appsAll();
    this.appsProfiles();
  },
};
</script>

<style scoped>
.container-sidebar-okan ::v-deep .v-list--nav {
  padding-right: 0 !important;
}

.sidebar__text-label {
  color: #141b33 !important;
  text-align: initial;
  font-weight: bold;
}

.v-avatar img {
  cursor: pointer !important;
}

.hidden-app {
  display: none !important;
}

.v-list-item--active {
  background-color: #466be3 !important;
  border-radius: 100px 0px 0px 100px !important;
  box-shadow: 0px 2px 8px rgba(62, 123, 250, 0.4),
    0px 8px 16px rgba(62, 123, 250, 0.16);
}

.v-btn--active {
  background-color: #466be3 !important;
  border-radius: 100px 0px 0px 100px !important;
  margin-left: 10px !important;
  padding-left: 0px !important;

  box-shadow: 0px 2px 8px rgba(62, 123, 250, 0.4),
    0px 8px 16px rgba(62, 123, 250, 0.16);
}

.v-list-item--active .icon-item::before {
  color: #ffffff !important;
}

.v-list-item--link:before {
  background-color: transparent;
}

.v-step {
  background: #2702c0 !important;
  font-size: 19px;
  max-width: 420px !important;
}

.v-step__button {
  background-color: #28abe2 !important;
  font-weight: bold !important;
  font-size: 15px !important;
}

.v-footer {
  position: absolute;
  display: none;
  height: 0;
}

.border-footer {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.txt-sidebar-d {
  color: #898989 !important;
  font-family: "RobotoRegular" !important;
  font-size: 17px !important;
}

.v-list-item--active .txt-sidebar-d {
  color: #fff !important;
  font-family: "proximaNovaRegular" !important;
  font-size: 17px !important;
}

.v-item-group.v-bottom-navigation .v-btn.v-btn--active {
  color: #fff !important;
}

.v-btn--active .apps-blue {
  background-color: #ffffff !important;
  content: url("https://apps.okan.tools/icon/apps-blue.svg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.v-btn--active .more-blue {
  background-color: #ffffff !important;
  content: url("https://apps.okan.tools/icon/more-blue.svg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.v-btn--active .home-blue {
  background-color: #ffffff !important;
  content: url("https://apps.okan.tools/icon/home-blue.svg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.v-list-item--active .apps-while {
  content: url("https://apps.okan.tools/icon/more-apps.svg") !important;
  background-size: 100%;
  background-repeat: no-repeat;
}

.v-list-item--active .home-while {
  content: url("https://apps.okan.tools/icon/home-2.svg") !important;
  background-size: 100%;
  background-repeat: no-repeat;
}

.v-list-item--active .star-while {
  content: url("https://apps.okan.tools/icon/star-while.svg") !important;
  background-size: 100%;
  background-repeat: no-repeat;
}

.v-list-item--active .news-while {
  content: url("https://apps.okan.tools/icon/news-while.svg") !important;
  background-size: 100%;
  background-repeat: no-repeat;
}

.v-list-item--active .forms-while {
  content: url("https://apps.okan.tools/icon/form-while.svg") !important;
  background-size: 100%;
  background-repeat: no-repeat;
}

.v-list-item--active .profile-while {
  content: url("https://apps.okan.tools/icon/profile-while.svg") !important;
  background-size: 100%;
  background-repeat: no-repeat;
}

.v-list-item--active .question-while {
  content: url("https://apps.okan.tools/icon/question-while.svg") !important;
  background-size: 100%;
  background-repeat: no-repeat;
}

.v-list-item--active .ocupation-while {
  content: url("https://apps.okan.tools/icon/ocupation-while.svg") !important;
  background-size: 100%;
  background-repeat: no-repeat;
}

.v-list-item--active .document-while {
  /* content: url("http://localhost:8080/icon/document-while.svg") !important; */
  content: url("https://apps.okan.tools/icon/document-while.svg") !important;
  background-size: 100%;
  background-repeat: no-repeat;
}

.v-list-item--active .tyc-while {
  /* content: url("http://localhost:8080/icon/formularios-1-while.svg") !important;  */
  content: url("https://apps.okan.tools/icon/formularios-1while.svg") !important;
  background-size: 100%;
  background-repeat: no-repeat;
}

.v-list-item--active .reference-while {
  content: url("https://apps.okan.tools/icon/reference-while.svg") !important;
  /* content: url("http://apps.okan.tools/icon/document-while.svg") !important; */
  background-size: 100%;
  background-repeat: no-repeat;
}

.v-list-item--active .commision-while {
  content: url("http://apps.okan.tools/icon/comission-while.svg") !important;
  /* content: url("http://apps.okan.tools/icon/document-while.svg") !important; */
  background-size: 100%;
  background-repeat: no-repeat;
}

.v-list-item--active .chart-while {
  content: url("http://apps.okan.tools/icon/chart-gray.svg") !important;
  /* content: url("http://apps.okan.tools/icon/document-while.svg") !important; */
  background-size: 100%;
  background-repeat: no-repeat;
}

.v-list-item--active .microphone-white {
  content: url("http://appsdev.okan.tools/icon/microphone-white.svg") !important;
  /* content: url("http://apps.okan.tools/icon/document-while.svg") !important; */
  background-size: 100%;
  background-repeat: no-repeat;
}

.v-list-item--active .profile-remove-while {
  /* content: url("http://localhost:8080/icon/profile-remove-while.svg") !important; */
  content: url("http://appsdev.okan.tools/icon/document-while.svg") !important;
  background-size: 100%;
  background-repeat: no-repeat;
}

.v-list-item--active .user-cirlce-add-while {
  content: url("http://apps.okan.tools/icon/user-cirlce-add-while.svg") !important;
  /* content: url("http://appsdev.okan.tools/icon/document-while.svg") !important; */
  background-size: 100%;
  background-repeat: no-repeat;
}

.v-list-item--active .security-user-while {
  content: url("http://apps.okan.tools/icon/security-user-while.svg") !important;
  /* content: url("http://appsdev.okan.tools/icon/document-while.svg") !important; */
  background-size: 100%;
  background-repeat: no-repeat;
}

.icons-set {
  display: flex !important;
}

.sidebar__items:hover {
  background-color: #f0f0f0;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}

@media only screen and (max-width: 1023px) {
  .v-navigation-drawer {
    visibility: hidden;
  }

  .v-footer {
    display: block;
    height: 60px;
    position: fixed;
    padding: 0 !important;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
  }

  .v-btn--active {
    background-color: #ffffff !important;
    margin: 0 !important;
    border-radius: 0 !important;
    padding-left: 19.5555555556px !important;
  }

  .v-btn--active span {
    color: #466be3;
  }

  .v-btn--active .icon-item::before {
    color: #466be3 !important;
  }

  .v-btn--active img {
    fill: #466be3 !important;
  }

  .theme--light.v-btn {
    color: #eeeeee;
  }

  .icon-name {
    text-transform: capitalize;
    color: #ffffff;
    font-weight: 100;
    margin-top: 5px;
  }

  .v-btn {
    letter-spacing: normal;
  }

  .v-application .mb-3 {
    margin-bottom: 0 !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .v-btn:not(.v-btn--round).v-size--large {
    height: 60px !important;
  }

  .v-btn__content {
    flex-direction: column !important;
  }
}

@media only screen and (max-width: 375px) {
  .v-application .mb-3 {
    padding: 0 !important;
  }
}

.container-sidebar-okan .v-navigation-drawer__content {
  display: none !important;
}
</style>
